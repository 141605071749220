<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12 text-right">
				<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="surveysList" rowKey="user_id">
					<div slot="actions" slot-scope="record" class="text-right">
						<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
					</div>
				</a-table>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
	name: 'surveyComponent',
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Nombre de Encuesta',
					dataIndex: 'name',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'actions' },
					width: '10%',
					align: 'center',
				},
			],
		}
	},
	computed: {
		...mapGetters('surveys', ['spinnerLoader', 'spinnerLoaderLabel', 'surveysList']),
	},
	beforeMount() {
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			this.$store.dispatch('surveys/GET')
		},
		onEditRecord(id) {
			this.$store.dispatch('surveys/GET_ONE', id).then(() => {
				this.$router.push(`/administracion/encuestas/${id}`)
			})
		},
	},
}
</script>